$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@import 'styles/helpers/global_variables';

.root {
  @include content-block;
  @include floating-text;
  @include print-avoid-break-inside;

  padding: 1.2rem 0 0;
  margin-bottom: 2rem;
  color: $contact-banner-color;
  background-color: $contact-banner-bg-color;
  margin-top: 7.5rem;

  .mainRow {
    position: relative;
  }

  .imageWrap {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    top: -7.5rem;
    left: -4rem;
    width: 300px;
    height: 300px;
    padding: 0;
    //width: 100%;
    //margin-bottom: -10rem;

    @include contact-banner-image-wrap;

    & > div {
      display: block !important;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .title {
    @include contact-banner-title;
  }

  .contentCol {
    margin-top: -5rem;

    .subTitle {
      @include font-uppercase-bold;

      margin-bottom: 1rem;
    }

    .signature {
      font-size: 0.9rem;
      margin-bottom: 1.5rem;

      .name {
        text-transform: uppercase;
      }

      .jobTitle {
        font-style: italic;
      }
    }

    .contactBlock {
      margin-bottom: 1rem;

      .icon {
        img {
          width: 60px;
          height: auto;
        }
      }

      .link {
        @include font-uppercase-bold;

        a {
          text-decoration: none;
          color: inherit;
        }
      }

      .openingHours {
        font-size: 0.9rem;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .imageWrap {
      left: -3rem;
    }
    .contentCol {
      margin-top: -7rem;
    }
  }

  @include media-breakpoint-up(md) {
    .imageWrap {
      left: -4rem;
    }
    .contentCol {
      margin-top: -10rem;
    }
  }

  @include media-breakpoint-up(lg) {
    .imageWrap {
      width: 365px;
      height: 365px;
      left: -4rem;
      top: -6rem;
    }
    .contentCol {
      margin-top: -12rem;
    }
  }
}
