$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@import 'styles/helpers/global_variables';

$ribbonBgColor: $signal;

.root {
  .ribbon {
    @include branded-button-font;

    height: 2.5rem;
    font-size: 1rem;
    padding: 9px 40px 9px 48px;
    float: left;
    text-align: center;
    text-decoration: none;
    color: white;
    position: relative;
    background: $ribbonBgColor;
    min-width: 11.3rem;
    width: auto;
    line-height: 1.375rem;
    transition: all 0.2s ease-in-out;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -1.25rem;
      border: 1.25rem solid $ribbonBgColor;
      border-right-color: transparent !important;
      right: -1.375rem;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      padding: 9px 40px 9px 52px;
    }

    @include media-breakpoint-up(sm) {
      font-size: 1.06rem;
    }
  }
}
