$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@import 'styles/helpers/global_variables';

.root {
  //margin-bottom: 0.6rem;
  position: relative;

  &.withDecoration {
    @include title-block-with-decoration
  }

  .visible {
    position: absolute;
    z-index: 2;
    margin-top: 0.5rem;
  }

  .positioner {
    opacity: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: inline-block;
    @include heading-common;
  }

  h2 {
    @include heading-h2;
  }
}

.subTitle {
  display: inline-block;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  @include font-uppercase-bold;
}
